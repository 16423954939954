@import '~antd/dist/antd.css';

@font-face {
  font-family: Consolas;
  src: url(../assets/fonts/consolas/Consolas.ttf);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Alerts */
.ant-alert-success{
  border-color: var(--success-light);
  background-color: var(--success-lighter);
}
.ant-alert-success .ant-alert-icon {
  color: var(--success-medium);
}

.ant-alert-info{
  border-color: var(--info-light);
  background-color: var(--info-lighter);
}
.ant-alert-info .ant-alert-icon {
  color: var(--info-medium);
}

.ant-alert-warning{
  border-color: var(--warning-light);
  background-color: var(--warning-lighter);
}
.ant-alert-warning .ant-alert-icon {
  color: var(--warning-medium);
}

.ant-alert-error{
  border-color: var(--error-light);
  background-color: var(--error-lighter);
}
.ant-alert-error .ant-alert-icon {
  color: var(--error-medium);
}
/* End alerts */
.ant-switch-checked {
  background-color: var(--green-sea_medium);
}

.ant-spin-text {
  color: var(--green-sea_medium);
}
.ant-spin-dot-item {
  background-color: var(--green-sea_medium);
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: var(--neutral_lighter); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--neutral_light); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--neutral_light); 
}